export const specialDrinks = [
  {
    title: "Aperol Spritz",
    consist: ["Aperol", "Sparkling Wine", "Orange", "Soda Water"],
    price: 120,
  },
  {
    title: "White Russian",
    consist: ["Vodka", "Kahlua", "Cream"],
    price: 120,
  },
  {
    title: "Michelada",
    consist: ["Vodka", "Beer", "Tomato Juice", "Spices"],
    price: 120,
  },
  {
    title: "GTA",
    consist: ["Gin", "Tonic", "Aloe"],
    price: 100,
  },
  {
    title: "Campari Orange",
    consist: ["Campari", "Orange Juice"],
    price: 90,
  },
  {
    title: "Screw Driver",
    consist: ["Vodka", "Orange Juice"],
    price: 90,
  },
  {
    title: "Floricică",
    consist: ["Scotch", "Eldelflower Liqueur", "Soda Water"],
    price: 90,
  },
  {
    title: "Jager Morse",
    consist: ["Jagermeister", "Cherry Juice"],
    price: 90,
  },
  {
    title: "Cuba Libre",
    consist: ["Rum", "Coca-Cola", "Lime"],
    price: 90,
  },
  {
    title: "Limoncello Tonic",
    consist: ["Limoncello", "Tonic"],
    price: 80,
  },
];

export const sprits = [
  {
    type: "Whiskey",
    options: [
      {
        title: "Monkey Shoulder",
        price: 80,
      },
      {
        title: "Tullamore Dew",
        price: 60,
      },
    ],
  },
  {
    type: "Gin",
    options: [
      {
        title: "Gordons",
        price: 60,
      },
    ],
  },
  {
    type: "Rum",
    options: [
      {
        title: "Captain Morgan",
        price: 60,
      },
    ],
  },
  {
    type: "Vodka",
    options: [
      {
        title: "Хортиця",
        price: 60,
      },
    ],
  },
  {
    type: "Liqueur",
    options: [
      {
        title: "Jagermeister",
        price: 60,
      },
    ],
  },
  {
    type: "Tequila",
    options: [
      {
        title: "Jose Cuervo Silver",
        price: 60,
      },
    ],
  },
  {
    type: "Beer",
    types: ["Heineken"],
    amount: 33,
    options: [
      {
        title: "Heineken",
        price: 60,
      },
    ],
  },
  {
    type: "Sparkling Wine",
    options: [
      {
        title: "Cricova Brut",
        price: 300,
      },
    ],
    amount: 77,
  },
];

export const softDrinks = [
  {
    title: "Red Bull",
    amount: 25,
    price: 60,
  },
  {
    title: "Borjomi",
    amount: 33,
    price: 50,
  },
  {
    title: "Lemonade",
    amount: 25,
    price: 40,
  },
  {
    title: "Coca-Cola",
    amount: 25,
    price: 30,
  },
  {
    title: "Juice",
    amount: 25,
    price: 30,
  },
  {
    title: "Water",
    amount: 50,
    price: 30,
  },
];

export const bottledCoctails = [
  {
    title: "Gimlet",
    price: 90,
  },
  {
    title: "Grappy",
    price: 90,
  },
];

export const tinctures = [
  {
    title: "Limoncello",
    price: 50,
  },
  {
    title: "Spiced Cherry",
    price: 50,
  },
];
