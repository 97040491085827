<template>
  <div class="menu">
    <p class="menu__type">Special Mix Drinks</p>
    <div
      v-for="(drink, key) in specialDrinks"
      :key="key"
      class="menu__position"
    >
      <div class="menu__position__title">
        <span>{{ drink.title }}</span>
        <ul v-for="(consist, key2) in drink.consist" :key="key2">
          <li>{{ consist }}</li>
        </ul>
      </div>
      <div class="menu__price">MDL {{ drink.price }}.00</div>
    </div>

    <div class="spacer"></div>

    <p class="menu__type">BOTTLED COCKTAILS <span>(120ml)</span></p>
    <div
      v-for="(drink, key) in bottledCoctails"
      :key="key"
      class="menu__position"
    >
      <div class="menu__position__soft">
        <p>{{ drink.title }}</p>
      </div>
      <div class="menu__price">MDL {{ drink.price }}.00</div>
    </div>
    <div class="spacer"></div>

    <p class="menu__type">spirits <span>(50ml)</span></p>
    <div v-for="(drink, key) in sprits" :key="key" class="menu__spirits">
      <p class="menu__spirits__type">{{ drink.type }}</p>
      <div class="menu__spirits__title">
        <ul v-for="(consist, key2) in drink.options" :key="key2">
          <span>{{ consist.title }}</span>
          <div class="menu__spirits__price">MDL {{ consist.price }}.00</div>
        </ul>
      </div>
    </div>
    <div class="spacer"></div>

    <p class="menu__type">TINCTURES <span>(50ml)</span></p>
    <div v-for="(drink, key) in tinctures" :key="key" class="menu__position">
      <div class="menu__position__soft">
        <p>{{ drink.title }}</p>
      </div>
      <div class="menu__price">MDL {{ drink.price }}.00</div>
    </div>
    <div class="spacer"></div>

    <p class="menu__type">Soft DRINKS</p>
    <div v-for="(drink, key) in softDrinks" :key="key" class="menu__position">
      <div class="menu__position__soft">
        <p>
          {{ drink.title }}
          <span class="prefix" v-if="drink.amount"
            >({{ drink.amount }}0ml)</span
          >
        </p>
      </div>
      <div class="menu__price">MDL {{ drink.price }}.00</div>
    </div>
    <div class="spacer"></div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import {
  specialDrinks,
  softDrinks,
  bottledCoctails,
  sprits,
  tinctures,
} from "./../constants/menu";

export default {
  setup() {
    return {
      specialDrinks,
      bottledCoctails,
      sprits,
      softDrinks,
      tinctures,
    };
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Comfortaa;
  src: url("/fonts/Comfortaa-VariableFont_wght.ttf");
  font-style: normal;
}

@font-face {
  font-family: Comfortaa-bold;
  src: url("/fonts/Comfortaa-Bold.ttf");
  font-style: normal;
}
.spacer {
  padding-bottom: 40px;
}

.prefix {
  font-size: 14px;
}

.menu {
  display: none;
  color: white;
  width: 100%;
  height: 100%;
  background-color: black;
  font-family: "Comfortaa", cursive;
  overflow: auto;
  &__type {
    text-align: left;
    font-size: 20px;
    margin: 0px;
    padding: 12px 0px 12px 20px;
    text-transform: uppercase;
    span {
      font-size: 16px;
      text-transform: lowercase;
    }
  }

  &__spirits {
    text-align: left;
    span {
      min-width: 200px;
    }
    ul {
      display: flex;
      text-align: left;
      justify-content: space-around;
    }
    &__title {
      font-size: 20px;
      font-family: Comfortaa-bold;
      ul:last-child {
        padding-bottom: 20px;
      }
    }
    &__price {
      font-family: Comfortaa;
      font-size: 16px;
    }
    &__type {
      padding-left: 27px;
    }
  }
  &__position {
    display: flex;
    justify-content: space-around;
    text-align: left;

    &__soft {
      text-align: left;
      min-width: 200px;
      padding-bottom: 5px;
      span {
        font-size: 14px;
      }
      p {
        font-size: 20px;
        font-family: Comfortaa-bold;
      }
    }

    &__title {
      text-align: left;
      min-width: 200px;
      padding-bottom: 20px;
      span {
        font-size: 20px;
        font-family: Comfortaa-bold;
      }
      &--sprits {
        font-size: 16px;
        margin: 0;
      }
      ul {
        font-size: 14px;
        padding: 0px;
        margin: 0px;
        list-style: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .menu {
    display: block;
  }
}
</style>
